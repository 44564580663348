.table-filter-select__control {
    height: 40px;
}

.form-control-btn{
    height: 40px;
    margin-bottom:0;
    margin-top:.1rem;
}

.react-select__control{
    max-height: 40px;
}

.ReactTable .rt-th, .ReactTable .rt-td{
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
}
/* Override React-Table default styling. */
.filterable-table-container .ReactTable .rt-table {
    overflow: visible;
}

.filterable-table-container .ReactTable.-disable-actions .rt-thead .rt-resizable-header:last-child {
    overflow: visible;
}

.filterable-table-container .ReactTable .rt-table {
    display: table;
    table-layout: auto;
}

.filterable-table-container .ReactTable .rt-thead{
    display: table-header-group;
}

.filterable-table-container .ReactTable .rt-tbody {
    display: table-row-group;
    overflow: auto;
}
.filterable-table-container .ReactTable .rt-tr {
    display: table-row;
}
    /* End React-Table */
/* Add option when the last column is not an action list*/
.ReactTable.-disable-actions .rt-thead .rt-th:last-child {
    margin: inherit;
    text-align: left;
}

.messageBox {
    position: absolute !important;
    top: -40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-color: rgb(85, 85, 85) transparent transparent;
    width: 250px;
    transition: opacity 0.3s ease 0s;
    background-color: rgb(85, 85, 85);
    text-align: center;
    opacity: 0.8;
    z-index: 1;
}

.messageBox::after {
    content: "";
}

.form-check.radio-inline {
    padding-left: 0 !important;
    display: inline-block;
}

.form-check.radio-inline input[type="radio"] + .form-check-sign::after, .form-check-radio input[type="radio"] {
    margin-left: -30px;
}

.form-check.radio-inline.form-check-radio .form-check-sign::before {
    left: 25px;
}

.form-check.radio-inline.form-check-radio input[type="radio"]:checked + .form-check-sign::after {
    left: 32px;
}

.form-check.radio-inline.form-check-radio .form-check-label {
    padding-left: 1.7rem;
    margin-right: 1rem;
}

.modal-header button.close {
}

.modal-header button.close span:first-of-type {
}

.modal-dialog.modal-xl {
    top: 5%;
    padding-top: 0;
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.navbar .navbar-nav .nav-item .nav-link [class^="fa"].fa-xs {
    font-size: 1.6rem;
}

.navbar .navbar-nav .nav-item .nav-link [class^="fa"].fa-xs {
    font-size: 1.6rem;
}

label.required::after {
    content: '\00A0*';
    color: #FB404B;
}

.middle-btn {
    margin-top: 2.5rem;
}

.wrap-unset {
    white-space: unset !important;
}

.adjustments .code-col{
    width: 15%;
}

.adjustments .last-updated-col{
    width: 11.5%;
}
.adjustments .optional-code-col{
    width: 11.5%;
}
.adjustments .free-goods-col{
    width: 11.5%;
}

.adjustments .enabled-col{
    width: 11.5%;
}

.adjustments .last-updated-col .rdtPicker{
    right:0;
}

.adjustments .last-updated-col .rdtPicker::before {
    right: 12px;
    left: unset;
}

.adjustments .last-updated-col .rdtPicker::after {
    right: 12px;
    left: unset;
}

.login-height{
    min-height: calc(100vh - 173px);
}

.login-logo{
    width: 75%;
    height: 75%;
}

.label-text-logo{
    width: 75%;
    position: absolute;
    right: -35%;
    top: 80%;
    transform: translate(-50%, -50%);
}

@media (min-width: 576px){
    .label-text-logo{
        font-size: 0.4rem;
    }
}

@media (min-width: 992px){
    .label-text-logo{
        font-size: 0.6rem;
    }
}

@media (min-width: 1200px){
    .label-text-logo{
        font-size: 0.65rem;
    }
}

@media (min-width: 1400px){
    .label-text-logo{
        font-size: 0.7rem;
    }
}

.footer-notLink{
    cursor: default;
    padding: 10px 0;
    color: #9A9A9A;
}

.footer-notLink:hover{
    color: #797979
}